import { useApi } from '~/restAPI';
import { Deeplink } from '~/restAPI/Deeplink';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server && to.fullPath.includes('addProductItem')) {
    const { activeMenuCategory } = useActiveMenuCategory();
    const { createApiInstance } = useApi();
    const deeplinkApi = createApiInstance(Deeplink);

    try {
      await deeplinkApi.resolveLinkUsingPost({ link: to.fullPath }, { format: 'json' });

      return navigateTo('/cart');
    } catch (e) {
      return navigateTo(`/${activeMenuCategory.value}?errorMessage=${e.data.humanMessage}`);
    }
  }

  return undefined;
});
