/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddressDTO,
  Api2ResponseOfBoolean,
  Api2ResponseOfCountriesDTO,
  Api2ResponseOfListOfAddressDTO,
  Api2ResponseOfListOfCityDTO,
  Api2ResponseOfListOfCountriesDTO,
  Api2ResponseOfListOfstring,
  ApiV2AddressCityListResponseDTO,
  ApiV2AddressCountyListResponseDTO,
  ApiV2AddressListResponseDTO,
  ApiV2InternationalAddressCityListResponseDTO,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Address<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags address-controller-api-v-2
   * @name GetBigCitiesUsingGet
   * @summary getBigCities
   * @request GET:/api/v2/address/big-cities
   */
  getBigCitiesUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfAddressDTO, void>({
      path: `/api/v2/address/big-cities`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-api-v-2
   * @name GetCitiesUsingGet
   * @summary getCities
   * @request GET:/api/v2/address/cities
   */
  getCitiesUsingGet = (
    query?: {
      /** str */
      str?: string;
      /** isDaDataEnabled */
      isDaDataEnabled?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfAddressDTO, void>({
      path: `/api/v2/address/cities`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-api-v-2
   * @name GetStreetUsingGet
   * @summary Find street suggestions by street name and city/settlement
   * @request GET:/api/v2/address/street
   */
  getStreetUsingGet = (
    query?: {
      /** Part or full street name */
      query?: string;
      /** cityFiasId. Only for russian addresses. If cityFiasId and settlementFiasId is empty - always return empty collection. */
      cityFiasId?: string;
      /** settlementFiasId. Only for russian addresses. If cityFiasId and settlementFiasId is empty - always return empty collection. */
      settlementFiasId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfAddressDTO, void>({
      path: `/api/v2/address/street`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-api-v-2
   * @name GetRegionsUsingGet
   * @summary getRegions
   * @request GET:/api/v2/address/regions
   */
  getRegionsUsingGet = (
    query?: {
      /** str */
      str?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfstring, void>({
      path: `/api/v2/address/regions`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-api-v-2
   * @name GetSearchHistoryUsingGet
   * @summary getSearchHistory
   * @request GET:/api/v2/address/search-history
   */
  getSearchHistoryUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfListOfAddressDTO, void>({
      path: `/api/v2/address/search-history`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-api-v-2
   * @name AddToSearchHistoryUsingPut
   * @summary addToSearchHistory
   * @request PUT:/api/v2/address/search-history
   */
  addToSearchHistoryUsingPut = (data: AddressDTO, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/address/search-history`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-international-api-v-2
   * @name GetCountriesGet
   * @summary getCountries
   * @request GET:/api/v2/address/countries
   */
  getCountriesGet = (
    query?: {
      context?: "APPLICATION" | "SELLER_ADDRESS" | "BUYER_ADDRESS" | "ALL_COUNTRIES" | "PHONE";
      /** @default false */
      unionVersion?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfCountriesDTO, void>({
      path: `/api/v2/address/countries`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-international-api-v-2
   * @name DetermineCountryGet
   * @summary determineCountry
   * @request GET:/api/v2/address/determineCountry
   */
  determineCountryGet = (
    query?: {
      context?: "APPLICATION" | "SELLER_ADDRESS" | "BUYER_ADDRESS" | "ALL_COUNTRIES" | "PHONE";
      store?: string;
      locales?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfCountriesDTO, void>({
      path: `/api/v2/address/determineCountry`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-international-api-v-2
   * @name GetBigCitiesInternationalisation
   * @summary getBitCitiesInternationalisation
   * @request GET:/api/v2/address/big-cities-int
   */
  getBigCitiesInternationalisation = (
    query?: {
      /** @format int32 */
      countryId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfCityDTO, void>({
      path: `/api/v2/address/big-cities-int`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-international-api-v-2
   * @name GetSearchHistoryInternationalisation
   * @summary getSearchHistoryInternationalisation
   * @request GET:/api/v2/address/city-search-history-int
   */
  getSearchHistoryInternationalisation = (
    query?: {
      /** @format int32 */
      countryId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfCityDTO, void>({
      path: `/api/v2/address/city-search-history-int`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags address-controller-international-api-v-2
   * @name PutSearchHistoryInternationalisation
   * @summary putSearchHistoryInternationalisation
   * @request PUT:/api/v2/address/city-search-history-int/{cityId}
   */
  putSearchHistoryInternationalisation = (cityId: number, params: RequestParams = {}) =>
    this.request<Api2ResponseOfBoolean, void>({
      path: `/api/v2/address/city-search-history-int/${cityId}`,
      method: "PUT",
      ...params,
    });
  /**
   * No description
   *
   * @tags UserAddress
   * @name GetInternationalCountryList
   * @summary Get countries
   * @request GET:/api/v2/address/countries-int
   */
  getInternationalCountryList = (
    query?: {
      /** search string */
      str?: string;
      ableToPickup?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2AddressCountyListResponseDTO, any>({
      path: `/api/v2/address/countries-int`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags UserAddress
   * @name GetInternationalCityListAdmin
   * @summary Get cities
   * @request GET:/api/v2/address/cities-int-admin
   */
  getInternationalCityListAdmin = (
    query: {
      /**
       * country ID
       * @format int64
       */
      countryId: number;
      /** search string */
      str?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2AddressCityListResponseDTO, any>({
      path: `/api/v2/address/cities-int-admin`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags UserAddress
   * @name GetCitiesCis
   * @summary Get cities in CIS
   * @request GET:/api/v2/address/cities-cis
   */
  getCitiesCis = (
    query?: {
      /** search string */
      query?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2AddressListResponseDTO, any>({
      path: `/api/v2/address/cities-cis`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags UserAddress
   * @name GetCitiesUnrestricted
   * @summary Get cities with no settlement type restrictions
   * @request GET:/api/v2/address/cities-unrestricted
   */
  getCitiesUnrestricted = (
    query?: {
      /** search string */
      query?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2AddressListResponseDTO, any>({
      path: `/api/v2/address/cities-unrestricted`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags UserAddress
   * @name GetCitiesInternationalisation
   * @summary Get cities
   * @request GET:/api/v2/address/cities-int
   */
  getCitiesInternationalisation = (
    query: {
      /** search string */
      query?: string;
      /**
       * country ID
       * @format int64
       */
      countryId: number;
      /** Include only supported cities */
      supportedOnly?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2InternationalAddressCityListResponseDTO, any>({
      path: `/api/v2/address/cities-int`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags UserAddress
   * @name GetAddressSuggestions
   * @summary Get address suggestions
   * @request GET:/api/v2/address/suggestions
   */
  getAddressSuggestions = (
    query?: {
      /** search string */
      query?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ApiV2AddressListResponseDTO, any>({
      path: `/api/v2/address/suggestions`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
